export function formatCreationDate({
  date,
  timeZone,
}: {
  date: string;
  timeZone?: string;
}): string {
  const dateFormatter = new Intl.DateTimeFormat(process.env.REACT_APP_INTL_LANGUAGE, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone,
  });
  return dateFormatter.format(new Date(date));
}
