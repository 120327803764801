import { Callout, Card, List, ListItem } from '@tremor/react';
import { CardTitle, SkeletonLoader } from '../../../../../components';
import { useQuery } from '@tanstack/react-query';
import { useGetUserWebsite } from '../../../../../requests/api/back-office.requests';
import { formatCreationDate } from '../../../../../utils';
import {
  formatDataStreamType,
  formatRetentionTime,
} from './back-office-user-detailed-website-card.utils';

function BooleanValue({ value }: { value: boolean }) {
  return (
    <span className={value ? 'text-green-600' : 'text-red-700'}>
      {value ? 'yes' : 'no'}
    </span>
  );
}

function RetentionTime({ retentionTime }: { retentionTime: string }) {
  return (
    <span
      className={
        retentionTime === 'TWO_MONTHS'
          ? 'text-red-700'
          : retentionTime === 'FOURTEEN_MONTHS'
            ? 'text-green-600'
            : ''
      }
    >
      {formatRetentionTime(retentionTime)}
    </span>
  );
}

export default function BackOfficeUserDetailedWebsiteCard({
  userId,
  websiteId,
}: {
  userId: number;
  websiteId: string;
}) {
  const getUserWebsite = useGetUserWebsite();
  const userWebsite = useQuery({
    queryKey: ['getBackOfficeUserWebsite', websiteId],
    queryFn: () => getUserWebsite({ userId, websiteId }),
  });

  return (
    <Card>
      <CardTitle title="Website details" />
      <div className="mt-4">
        {userWebsite.isSuccess && !userWebsite.isRefetching && (
          <div className="space-y-4">
            <div className="space-y-1">
              <h4 className="text-tremor-default font-semibold text-tremor-content-strong">
                Google Analytics account
              </h4>
              <List>
                <ListItem>
                  <span className="font-medium">Name</span>
                  {userWebsite.data.ga4Configuration.account.name}
                </ListItem>
                <ListItem>
                  <span className="font-medium">Creation date</span>
                  {formatCreationDate({
                    date: userWebsite.data.ga4Configuration.account.createdAt,
                  })}
                </ListItem>
                <ListItem>
                  <span className="font-medium">ID</span>
                  {userWebsite.data.ga4Configuration.account.id}
                </ListItem>
              </List>
            </div>
            <div className="space-y-1">
              <h4 className="text-tremor-default font-semibold text-tremor-content-strong">
                Google Analytics property
              </h4>
              <List>
                <ListItem>
                  <span className="font-medium">Name</span>
                  {userWebsite.data.ga4Configuration.property.name}
                </ListItem>
                <ListItem>
                  <span className="font-medium">Creation date</span>
                  {formatCreationDate({
                    date: userWebsite.data.ga4Configuration.property.createdAt,
                  })}
                </ListItem>
                <ListItem>
                  <span className="font-medium">Time zone</span>
                  {userWebsite.data.ga4Configuration.property.timeZone}
                </ListItem>
                <ListItem>
                  <span className="font-medium">Event data retention time</span>
                  <RetentionTime
                    retentionTime={
                      userWebsite.data.ga4Configuration.property.eventDataRetentionTime
                    }
                  />
                </ListItem>
                <ListItem>
                  <span className="font-medium">User data retention time</span>
                  <RetentionTime
                    retentionTime={
                      userWebsite.data.ga4Configuration.property.userDataRetentionTime
                    }
                  />
                </ListItem>
                <ListItem>
                  <span className="font-medium">User data is reset on new activity</span>
                  <BooleanValue
                    value={
                      userWebsite.data.ga4Configuration.property
                        .isUserDataResetOnNewActivity
                    }
                  />
                </ListItem>
                <ListItem>
                  <span className="font-medium">Events received yesterday</span>
                  <BooleanValue
                    value={
                      userWebsite.data.ga4Configuration.property
                        .hasReceivedEventsYesterday
                    }
                  />
                </ListItem>
                <ListItem>
                  <span className="font-medium">
                    Events received in the last 30 minutes
                  </span>
                  <BooleanValue
                    value={
                      userWebsite.data.ga4Configuration.property
                        .hasReceivedEventsInLast30Minutes
                    }
                  />
                </ListItem>
                <ListItem>
                  <span className="font-medium">ID</span>
                  {userWebsite.data.ga4Configuration.property.id}
                </ListItem>
              </List>
            </div>
            <div className="space-y-1">
              <h4 className="text-tremor-default font-semibold text-tremor-content-strong">
                Google Analytics data steams
              </h4>
              {userWebsite.data.ga4Configuration.dataSteams.length === 0 && (
                <div className="py-5 text-center text-tremor-default text-red-700">
                  No data stream found.
                </div>
              )}
              {userWebsite.data.ga4Configuration.dataSteams.map(dataStream => (
                <List>
                  <ListItem>
                    <span className="font-medium">Name</span> {dataStream.name}
                  </ListItem>
                  <ListItem>
                    <span className="font-medium">Creation date</span>
                    {formatCreationDate({
                      date: dataStream.createdAt,
                    })}
                  </ListItem>
                  <ListItem>
                    <span className="font-medium">Type</span>
                    <span
                      className={
                        dataStream.type === 'WEB_DATA_STREAM'
                          ? 'text-green-600'
                          : 'text-red-700'
                      }
                    >
                      {formatDataStreamType(dataStream.type)}
                    </span>
                  </ListItem>
                  <ListItem>
                    <span className="font-medium">Website URI</span>
                    {dataStream.websiteUri ? (
                      <a
                        href={dataStream.websiteUri}
                        className="text-tremor-brand"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {dataStream.websiteUri}
                      </a>
                    ) : (
                      '-'
                    )}
                  </ListItem>
                  <ListItem>
                    <span className="font-medium">Website measurement ID</span>
                    {dataStream.websiteMeasurementId ?? '-'}
                  </ListItem>
                  <ListItem>
                    <span className="font-medium">ID</span> {dataStream.id}
                  </ListItem>
                </List>
              ))}
            </div>
          </div>
        )}
        {(userWebsite.isPending || userWebsite.isRefetching) && (
          <SkeletonLoader className="h-36" />
        )}
        {userWebsite.isError && !userWebsite.isRefetching && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
