import { useQuery } from '@tanstack/react-query';
import { useGetUserWebsites } from '../../../../../requests/api/back-office.requests';
import {
  Button,
  CardTitle,
  SkeletonLoader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '../../../../../components';
import { Callout, Card } from '@tremor/react';

export default function BackOfficeUserWebsiteCard({
  userId,
  onSelectWebsite,
}: {
  userId: number;
  onSelectWebsite: (websiteId: string) => void;
}) {
  const getUserWebsites = useGetUserWebsites();

  const userWebsites = useQuery({
    queryKey: ['getBackOfficeUserWebsites'],
    queryFn: () => getUserWebsites(userId),
  });

  return (
    <Card>
      <CardTitle title="Websites" />
      <div className="mt-4 overflow-x-auto">
        <Table>
          {userWebsites.isSuccess && !userWebsites.isRefetching && (
            <TableBody>
              {userWebsites.data.websitesGroupedByGa4Account.map(
                ({ ga4Account, websites }) => (
                  <>
                    <tr className="border-t border-gray-200">
                      <th
                        scope="colgroup"
                        colSpan={3}
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        {ga4Account.name}
                      </th>
                    </tr>
                    {websites.length !== 0 ? (
                      websites.map(website => (
                        <TableRow key={website.id}>
                          <TableCell textAlign="left">{website.name}</TableCell>
                          <TableCell textAlign="left">{website.id}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => onSelectWebsite(website.id)}
                              variant="light"
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell textAlign="center" colspan={2}>
                          <span className="text-center text-tremor-label text-tremor-content">
                            No websites found.
                          </span>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ),
              )}
            </TableBody>
          )}
        </Table>
        {userWebsites.isSuccess &&
          !userWebsites.isRefetching &&
          userWebsites.data.websitesGroupedByGa4Account.length === 0 && (
            <div className="py-10 text-center text-tremor-label text-tremor-content">
              No Google Analytics account found.
            </div>
          )}
        {(userWebsites.isPending || userWebsites.isRefetching) && (
          <SkeletonLoader className="h-36" />
        )}
        {userWebsites.isError && !userWebsites.isRefetching && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
