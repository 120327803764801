import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export interface Ga4Account {
  id: string;
  name: string;
}

export interface Website {
  id: string;
  name: string;
}

export function useGetWebsites(): () => Promise<{
  websites: Website[];
  websitesGroupedByGa4Account: {
    ga4Account: Ga4Account;
    websites: Website[];
  }[];
  isUserConnectedToGoogleAnalytics: boolean;
}> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/websites');
    return data;
  };
}
