import { User } from '../../../../requests/api/back-office.requests';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  PaginationBar,
} from '../../../../components';
import { Link } from 'react-router-dom';
import AccountStatus from './AccountStatus';
import SubscriptionStatus from './SubscriptionStatus';
import { usePagination } from '../../../../utils';
import { useState } from 'react';

export default function UserList({ users }: { users: User[] }) {
  const [maxItemsPerPage] = useState(9);

  const {
    paginatedItems: paginatedUsers,
    currentPageIndex,
    setCurrentPageIndex,
    numberOfPages,
  } = usePagination({
    items: users,
    maxItemsPerPage,
  });

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell small textAlign="left">
              User
            </TableHeaderCell>
            <TableHeaderCell small>Email</TableHeaderCell>
            <TableHeaderCell small>First name</TableHeaderCell>
            <TableHeaderCell small>Last name</TableHeaderCell>
            <TableHeaderCell small>Account status</TableHeaderCell>
            <TableHeaderCell small>Subscription status</TableHeaderCell>
            <TableHeaderCell small>Plan</TableHeaderCell>
            <TableHeaderCell small>User ID</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedUsers.map(user => (
            <TableRow key={user.id}>
              <TableCell small textAlign="left" bold dark>
                <Link to={`/back-office/users/${user.id}`}>
                  {user.firstName} {user.lastName}
                </Link>
              </TableCell>
              <TableCell small>{user.email}</TableCell>
              <TableCell small>{user.firstName}</TableCell>
              <TableCell small>{user.lastName}</TableCell>
              <TableCell small>
                <AccountStatus status={user.status.account} />
              </TableCell>
              <TableCell small>
                <SubscriptionStatus status={user.status.subscription} />
              </TableCell>
              <TableCell small>{user.subscription.plan.name}</TableCell>
              <TableCell small>{user.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {numberOfPages > 1 && (
        <div className="mt-2">
          <PaginationBar
            numberOfPages={numberOfPages}
            currentPageIndex={currentPageIndex}
            onCurrentPageIndexChange={setCurrentPageIndex}
          />
        </div>
      )}
    </div>
  );
}
