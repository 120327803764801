import { Callout, Card } from '@tremor/react';
import { useListAllUsers } from '../../requests/api/back-office.requests';
import { useQuery } from '@tanstack/react-query';
import { formatNumber } from '../../utils';
import { UserList } from './common';
import { CardTitle, PageContainer, SkeletonLoader } from '../../components';

function MetricCard({ name, value }: { name: string; value: string }) {
  return (
    <Card>
      <dt className="text-tremor-default font-medium text-tremor-content">{name}</dt>
      <dd className="mt-2 flex items-baseline space-x-2.5">
        <span className="text-tremor-metric font-semibold text-tremor-content-strong">
          {value}
        </span>
      </dd>
    </Card>
  );
}

function MetricCardSkeletonLoader() {
  return <SkeletonLoader className="h-28" />;
}

export default function BackOfficePage() {
  const listAllUsers = useListAllUsers();

  const allUsers = useQuery({
    queryKey: ['allUsers'],
    queryFn: () => listAllUsers(),
  });

  return (
    <PageContainer>
      <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {allUsers.isSuccess && (
          <>
            <MetricCard name="Total users" value={formatNumber(allUsers.data.length)} />
            <MetricCard
              name="User synchro"
              value={formatNumber(
                allUsers.data.filter(user => user.status.account === 'ga4-connected')
                  .length,
              )}
            />
            <MetricCard
              name="Customers"
              value={formatNumber(
                allUsers.data.filter(
                  user =>
                    user.status.subscription === 'active' ||
                    user.status.subscription === 'pending cancellation',
                ).length,
              )}
            />
          </>
        )}
        {allUsers.isPending && (
          <>
            <MetricCardSkeletonLoader />
            <MetricCardSkeletonLoader />
            <MetricCardSkeletonLoader />
          </>
        )}
      </dl>
      <Card>
        <div className="flex items-center">
          <div className="px-1">
            <CardTitle title="Users" />
          </div>
        </div>
        <div className="mt-2 overflow-x-auto">
          {allUsers.isSuccess && <UserList users={allUsers.data} />}
          {allUsers.isPending && <SkeletonLoader className="h-36" />}
          {allUsers.isError && (
            <Callout title="An error has occurred" color="red">
              An error occurred while retrieving the data. Try refreshing the page.
            </Callout>
          )}
        </div>
      </Card>
    </PageContainer>
  );
}
